import { observer } from 'mobx-react-lite';

import { OptionsList } from 'components/OptionsList';
import { useStore } from 'hooks/useStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';

import { LegacyRate } from './LegacyRate';
import { Rate } from './Rate';

const Content = () => {
  const {
    siteFeatures,
    options,
    filter: { extraParams },
  } = useStore();

  return (
    <>
      <OptionsList
        options={options.getPaymentTypes()}
        selectedValues={extraParams.params}
        valueProperty="id"
        onChange={extraParams.setParam}
      />

      {siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.doubleRateFilter] ? <Rate /> : <LegacyRate />}
    </>
  );
};

const connected = observer(Content);

export { connected as Content };
