import Icon from '@components/icon';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { ReactNode } from 'react';

import { useStore } from 'hooks/useStore';

import background from './background.svg';

import css from './Popup.module.css';

const Popup = ({ children, heading }: { children: ReactNode; heading: string }) => {
  const { feedbackPopup } = useStore();

  return (
    <div className={cn(css.popup, 'glz-z-popover')}>
      <button className={css.closeButton} onClick={feedbackPopup.refuseToVote}>
        <Icon color="#8C969D" height={16} name="ic_close" width={16} />
      </button>

      <Image
        alt="new loads search"
        className={css.background}
        height={128}
        layout="responsive"
        objectPosition="top"
        src={background}
      />
      <div className={css.content}>
        <div className={css.heading}>{heading}</div>
        {children}
      </div>
    </div>
  );
};

const connected = observer(Popup);

export { connected as Popup };
