import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';

import css from '../Content.module.css';

const Initial = () => {
  const {
    app: { i18n },
    feedbackPopup,
  } = useStore();

  return (
    <div>
      <div className={css.text}>
        <ul className={css.list}>
          <li>{i18n.feedbackPopup.initial.text.favorites}</li>
          <li>{i18n.feedbackPopup.initial.text.offers}</li>
          <li>{i18n.feedbackPopup.initial.text.auctions}</li>
        </ul>
      </div>

      <div className={css.actions}>
        <button className={cn(css.action, 'glz-badge glz-is-light-gray')} onClick={feedbackPopup.like}>
          {i18n.feedbackPopup.initial.good}
        </button>
        <button className={cn(css.action, 'glz-badge glz-is-light-gray')} onClick={feedbackPopup.dislike}>
          {i18n.feedbackPopup.initial.bad}
        </button>
      </div>
    </div>
  );
};

const connected = observer(Initial);

export { connected as Initial };
