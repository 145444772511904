import Select from '@components/select';
import { observer } from 'mobx-react-lite';

import { RestrictedInput } from 'components/RestrictedInput';
import { inputRestriction } from 'constants/inputRestrictions';
import { useStore } from 'hooks/useStore';

import css from './LegacyRate.module.css';

const LegacyRate = () => {
  const {
    app: { i18n },
    filter: { rate },
  } = useStore();

  return (
    <div className={css.rateInputContainer}>
      <RestrictedInput
        clearZeroOnBlur
        classNames={{ wrapper: css.rateInputWrapper }}
        customRestrict={['excessLeadingZeros', 'leadingSymbols']}
        inputRestrictionType={inputRestriction.nonNegativeFloatDecimalLimit}
        maxDecimalPlaces={3}
        maxLength={7}
        placeholder={i18n.common.from}
        value={rate.value.value}
        onValueChange={rate.value.setValueFromInput}
      />

      <Select
        className={css.rateTypeSelect}
        classNames={{ select: css.rateTypesInnerSelect }}
        options={rate.currencyTypeId.options}
        value={rate.currencyTypeId.option}
        onChange={rate.currencyTypeId.setOption}
      />
    </div>
  );
};

const connected = observer(LegacyRate);

export { connected as LegacyRate };
