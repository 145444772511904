import { Disliked } from './Disliked';
import { Initial } from './Initial';
import { Liked } from './Liked';

const Content = () => {};

Content.Initial = Initial;
Content.Liked = Liked;
Content.Disliked = Disliked;

export { Content };
