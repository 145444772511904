import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { favoritesFAQ } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import { Content } from './Content';
import { ContentSwitcher } from './ContentSwitcher';

import css from './FollowedLoads.module.css';

const FollowedLoads = () => {
  const {
    app: { i18n, env },
  } = useStore();

  return (
    <div className={css.container}>
      <div className={css.header}>
        <ContentSwitcher />

        <div className={cn(css.links, css.links_newTab)}>
          <a
            className={cn('glz-link glz-is-gray', css.more)}
            href={interpolate(favoritesFAQ, { env })}
            rel="noreferrer"
            target="_blank"
          >
            {i18n.favorites.more}
          </a>
        </div>
      </div>
    </div>
  );
};

FollowedLoads.Content = Content;

const connected = observer(FollowedLoads);

export { connected as FollowedLoads };
