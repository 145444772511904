import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { FeedbackPopupStates } from 'store/FeedbackPopupStore/FeedbackPopupStore';

import { Content } from './Content';
import { Popup } from './Popup';

const FeedbackPopup = () => {
  const {
    app: { i18n },
    feedbackPopup,
  } = useStore();

  if (!feedbackPopup.visible) return null;

  return (
    <Popup heading={i18n.feedbackPopup[feedbackPopup.state].heading}>
      {feedbackPopup.state === FeedbackPopupStates.initial && <Content.Initial />}
      {feedbackPopup.state === FeedbackPopupStates.liked && <Content.Liked />}
      {feedbackPopup.state === FeedbackPopupStates.disliked && <Content.Disliked />}
    </Popup>
  );
};

const connected = observer(FeedbackPopup);

export { connected as FeedbackPopup };
