import { observer } from 'mobx-react-lite';

import { useStore } from 'hooks/useStore';
import { followedLoadsTabIds } from 'store/UIStore/UIStore';

import { ContentWithAuctions } from './ContentWithAuctions';
import { ContentWithFavorites } from './ContentWithFavorites';
import { ContentWithOffers } from './ContentWithOffers';

const Content = () => {
  const {
    ui: { followedLoadsTab },
  } = useStore();

  // TODO:
  // эти компоненты похожи, но чуток отличаются всё же
  // стоит попробовать их переделать в один компонент и прокидовать в него пропсы
  return (
    <>
      {followedLoadsTab.data === followedLoadsTabIds.onlyWithFavorites && <ContentWithFavorites />}

      {followedLoadsTab.data === followedLoadsTabIds.onlyWithOffers && <ContentWithOffers />}

      {followedLoadsTab.data === followedLoadsTabIds.onlyWithAuctions && <ContentWithAuctions />}
    </>
  );
};

const connected = observer(Content);

export { connected as Content };
