import Icon from '@components/icon';
import Select, { SelectOption } from '@components/select';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { ActionPopover } from 'components/ActionPopover';
import { Field } from 'components/Field';
import { FollowedLoadsPagination } from 'components/FollowedLoadsPagination';
import { useStore } from 'hooks/useStore';
import { PrettyLoad } from 'layout/Search/PrettyLoad';
import { TabsContent } from 'layout/TabsContent';
import { followedLoadsTabIds } from 'store/UIStore/UIStore';

import css from './ContentWithFavorites.module.css';

const ContentWithFavorites = () => {
  const {
    app: { i18n },
    view,
    followedLoads: { favorites, loadsWithOffers, loadsWithAuctions },
    ui: { pagination, followedLoadsTab, handleTabChange },
  } = useStore();

  const callbacks = useMemo(
    () => ({
      hideLoads: () => {},
      selectLoads: favorites.selectedLoads.toggleSelection,
      onRemoveLoadFromFavorites: favorites.updateLoads,
      onOfferChange: loadsWithOffers.updateLoads,
      onAuctionChange: loadsWithAuctions.updateLoads,
      onFavoritesFAQPopoverClose: favorites.handleFavoritesFAQPopoverClose,
      onFilterTabOpen: handleTabChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleItemsPerPageChange = (option: SelectOption) => {
    const newPage = Math.ceil(
      ((pagination.pages.onlyWithFavorites - 1) * pagination.favoritesPerPage.data + 1) / option.value,
    );
    pagination.setCurrentPage(newPage);
    pagination.favoritesPerPage.setOption(option);
    favorites.updateLoads();
  };

  if (favorites.count === 0) {
    return (
      <TabsContent.NoContent
        className={css.noContent}
        desc={''}
        icon={<Icon color="var(--glz-color-neutral-tone-4)" name="ic_cargo-starred" size="xl" />}
        title={i18n.favorites.noContent.favorites.title}
      />
    );
  }

  return (
    <div>
      <div className={css.heading}>
        <h2 className={cn(css.totalItems, 'glz-h glz-is-size-2')}>{view.pluralizedCount(favorites.count, 'loads')}</h2>

        <FollowedLoadsPagination />
      </div>

      <div className={cn(css.actions, css.actions_top)}>
        <div className={css.links}>
          {followedLoadsTab.data === followedLoadsTabIds.onlyWithFavorites && (
            <>
              <ActionPopover
                alternateAction={i18n.actionPopover.delete.atlernate}
                primaryAction={i18n.actionPopover.delete.primary}
                title={i18n.favorites.deleteUnactual.popover}
                onConfirm={favorites.removeUnactualFavorites}
              >
                <button className={cn('glz-link', css.link)}>{i18n.favorites.deleteUnactual.button}</button>
              </ActionPopover>

              <ActionPopover
                alternateAction={i18n.actionPopover.delete.atlernate}
                primaryAction={i18n.actionPopover.delete.primary}
                title={i18n.favorites.deleteAll.popover}
                onConfirm={favorites.removeAllFavorites}
              >
                <button className={cn('glz-link', css.link)}>{i18n.favorites.deleteAll.button}</button>
              </ActionPopover>
            </>
          )}
        </div>

        <Field isRow labelClassName={css.sortLabel} labelText={i18n.app.showLines}>
          <Select
            className={css.sortSelect}
            isSearchable={false}
            options={pagination.favoritesPerPage.options}
            value={pagination.favoritesPerPage.option}
            onChange={handleItemsPerPageChange}
          />
        </Field>
      </div>

      <PrettyLoad
        callbacks={callbacks}
        currentPage={pagination.pages.onlyWithFavorites}
        descriptor={{ isFavorites: true }}
        loads={favorites.loads}
        updateLoad={favorites.updateLoad}
      />

      {pagination.totalFavoritesPages > 1 && (
        <div className={cn(css.actions, css.actions_bottom)}>
          <FollowedLoadsPagination className={css.pagination} />

          <Field containerClassName={css.sortField} labelClassName={css.sortLabel} labelText={i18n.app.showLines}>
            <Select
              className={css.sortSelect}
              isSearchable={false}
              options={pagination.favoritesPerPage.options}
              value={pagination.favoritesPerPage.option}
              onChange={handleItemsPerPageChange}
            />
          </Field>
        </div>
      )}
    </div>
  );
};

const connected = observer(ContentWithFavorites);

export { connected as ContentWithFavorites };
