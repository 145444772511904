import Button from '@components/button';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';

import { Checkbox } from 'components/Checkbox';
import { useStore } from 'hooks/useStore';

import css from './Disliked.module.css';

const maxFeedbackLenght = 1000;

const Disliked = () => {
  const {
    app: { i18n },
    feedbackPopup,
  } = useStore();

  const { notUsing, confusing, noDifference, other, feedback } = feedbackPopup;

  const canSubmit = notUsing.value || confusing.value || noDifference.value || (other.value && feedback.value.length);

  return (
    <div className={cn(css.container, 'glz-has-background-light-blue')}>
      <Checkbox small checked={notUsing.value} className={css.checkbox} onChange={notUsing.setValueFromCheckbox}>
        {i18n.feedbackPopup.disliked.issues.notUsing}
      </Checkbox>

      <Checkbox small checked={confusing.value} className={css.checkbox} onChange={confusing.setValueFromCheckbox}>
        {i18n.feedbackPopup.disliked.issues.confusing}
      </Checkbox>

      <Checkbox
        small
        checked={noDifference.value}
        className={css.checkbox}
        onChange={noDifference.setValueFromCheckbox}
      >
        {i18n.feedbackPopup.disliked.issues.noDifference}
      </Checkbox>

      <Checkbox small checked={other.value} className={css.checkbox} onChange={other.setValueFromCheckbox}>
        {i18n.feedbackPopup.disliked.issues.other}
      </Checkbox>

      <div className={'glz-textarea-wrapper container-width'}>
        <textarea
          className={cn(css.feedback, 'glz-textarea glz-has-counter')}
          disabled={!other.data}
          maxLength={maxFeedbackLenght}
          placeholder={i18n.feedbackPopup.disliked.feedbackPlaceholder}
          value={feedback.value}
          onChange={feedback.setValueFromInput}
        />

        <div className="glz-counter-wrapper">
          <span className="glz-counter">{maxFeedbackLenght - feedback.value.length}</span>
        </div>
      </div>

      <Button disabled={!canSubmit} size="small" theme="primary" onClick={feedbackPopup.provideFeedback}>
        {i18n.feedbackPopup.disliked.submit}
      </Button>
    </div>
  );
};

const connected = observer(Disliked);

export { connected as Disliked };
