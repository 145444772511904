import { observer } from 'mobx-react-lite';

import { virtualWalletFaq } from 'constants/pages';
import { useStore } from 'hooks/useStore';
import { interpolate } from 'utils/string/format';

import { Warning } from './Warning';

const Warnings = () => {
  const {
    app: { i18n, env },
    savedFilters: { popup },
    view,
    subscriptions,
  } = useStore();
  if (!popup || !popup.hasSubscription.value) return null;

  const warnings = [];

  if (subscriptions.isCountersInitialized) {
    if (popup.willReachDailySubscriptionNotificationsLimit) {
      warnings.push(
        <Warning key="willReachDailySubscriptionNotificationsLimit">
          {i18n.filterPopup.warnings.dailySubscriptionLimit.firstPart}
          <strong>
            {' '}
            {view.pluralizedCount(
              subscriptions.counters?.dailySubscriptionNotificationsLimit || null,
              'notification',
            )}{' '}
          </strong>
          {i18n.filterPopup.warnings.dailySubscriptionLimit.secondPart}
        </Warning>,
      );
    }

    if (popup.willReachDailyNotificationsLimit) {
      warnings.push(
        <Warning key="willReachDailyNotificationsLimit">
          {i18n.filterPopup.warnings.dailyLimit.firstPart}{' '}
          <strong>
            {view.pluralizedCount(subscriptions.counters?.dailyNotificationsLimit || null, 'notification')}
          </strong>{' '}
          {i18n.filterPopup.warnings.dailyLimit.secondPart}
        </Warning>,
      );
    }
  }

  if (popup.isVirtuallyBlocked) {
    warnings.push(
      <Warning key="isUserVirtualBlocked">
        {i18n.filterPopup.warnings.isVirtuallyBlocked}{' '}
        <a className="glz-link" href={interpolate(virtualWalletFaq, { env })} rel="noreferrer" target="_blank">
          {i18n.common.readMore}
        </a>
      </Warning>,
    );
  }

  const { sms, site, email, mobile } = popup.channels;
  const onlySmsEnabled = sms.value && !site.value && !email.value && !mobile.value;
  const shouldShowNoFunds = popup.isLowBalance && !onlySmsEnabled;
  if (shouldShowNoFunds) {
    warnings.push(<Warning key="isUserBalanceTooLow">{i18n.filterPopup.warnings.noFunds}</Warning>);
  }

  const shouldShowNoFundsForSms = popup.validationResult?.isUserSmsBalanceTooLow && sms.value;
  if (shouldShowNoFundsForSms) {
    warnings.push(<Warning key="isUserSmsBalanceTooLow">{i18n.filterPopup.warnings.noFundsForSms}</Warning>);
  }

  const firstWarning = warnings[0];
  return <>{firstWarning}</>;
};

const connected = observer(Warnings);

export { connected as Warnings };
