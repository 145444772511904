import { zIndexSystem } from '@ati/uti';
import Tooltip from '@components/tooltip';
import cn from 'clsx';

import { TNotification } from 'store/Subscriptions/SubscribedFilter';
import { withConditionalWrapping } from 'utils/hocs/withConditionalWrapping';

import css from './NotificationIcon.module.css';

const ConditionalTooltip = withConditionalWrapping(Tooltip);
interface INotificationIcon {
  notification: TNotification;
  className?: string;
}

const NotificationIcon = ({ notification, className }: INotificationIcon) => {
  const tooltipContent = notification?.tooltip?.map((tooltip, index) => (
    <div key={index} className={css.tip}>
      <strong>{tooltip.title}</strong>
      <span>{tooltip.text}</span>
    </div>
  ));

  return (
    <ConditionalTooltip
      isWithoutWrapper
      content={tooltipContent}
      maxWidth={200}
      wrapIf={Boolean(notification.tooltip?.length)}
      zIndex={{ tip: zIndexSystem.abovePopup }}
    >
      <span className={cn(className, css.icon, css[notification.icon], css[notification.level])}></span>
    </ConditionalTooltip>
  );
};

export { NotificationIcon };
