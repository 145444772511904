import Skeleton from '@components/skeleton';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';

import { Tabs } from 'components/Tabs';
import { scrollTopAnchorId, searchScrollAnchorId, tabIds } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { FeedbackPopup } from 'layout/PopupsContainer/FeedbackPopup/FeedbackPopup';

import { BottomAdv } from './BottomAdv';
import { Filters } from './Filters';
import { Controls } from './Filters/Controls';
import { SearchButton } from './Filters/SearchButton';
import { FollowedLoads } from './FollowedLoads';
import { History } from './History';
import { Instructions } from './Instructions';
import { SavedFilters } from './SavedFilters';
import { SearchResults } from './SearchResults';
import { Trucks } from './Trucks';

import css from './Search.module.css';

const ThemeSwitch = dynamic(() => import('components/ThemeSwitch').then(mod => mod.ThemeSwitch), {
  ssr: false,
  loading: () => <Skeleton height={20} width={125} />,
});

const Search = () => {
  const {
    app: { i18n, searchResult, setLoading },
    ui: { tabs, isTabsPinned, toggleTabsPin, activeTab, setActiveTab, openLoginPopup, isMobile },
    filter,
    tabsData,
    profile,
  } = useStore();

  useEffect(() => {
    tabsData.fetchTabsData();

    setLoading('allResources', true);

    window.addEventListener('load', () => {
      setLoading('allResources', false);
    });
  }, [tabsData, setLoading]);

  return (
    <div className={css.container}>
      <div className={css.topPart}>
        <div className={css.titleContainer}>
          <h1 className={cn('glz-h glz-is-size-1', css.h1)} id={scrollTopAnchorId}>
            {i18n.app.h1}
          </h1>

          <ThemeSwitch />
        </div>

        <Tabs
          activeTab={activeTab}
          isPinned={isTabsPinned}
          tabsData={tabs}
          onChange={setActiveTab}
          onDisabledClick={openLoginPopup}
          onPin={toggleTabsPin}
        />

        {activeTab === tabIds.search && <Filters className={css.tab} />}

        {activeTab === tabIds.savedFilters && <SavedFilters className={css.tab} />}

        {activeTab === tabIds.history && <History className={css.tab} />}

        {activeTab === tabIds.byTrucks && <Trucks className={css.tab} />}

        {activeTab === tabIds.favorites && <FollowedLoads />}

        {activeTab === tabIds.search && (
          <>
            <div className={cn(css.row, css.bottomRow)} id={searchScrollAnchorId}>
              <Instructions />

              <div className={css.controlsContainer}>
                <Controls />
                <SearchButton />
              </div>
            </div>

            <div className={css.error}>{filter.error}</div>

            <div className={cn(css.banner, { [css.banner_hidden]: !searchResult || profile.data?.isPaidUser })}>
              {isMobile ? (
                <span ati-userdata-info="doc-preset=mobile_240_400" />
              ) : (
                <span ati-userdata-info="doc-preset=allpages_940_200" />
              )}
            </div>
          </>
        )}
      </div>

      <div className={css.bottomPart}>
        {activeTab === tabIds.search && <SearchResults />}

        {activeTab === tabIds.favorites && <FollowedLoads.Content />}

        <FeedbackPopup />

        {searchResult && <BottomAdv />}
      </div>
    </div>
  );
};

const connected = observer(Search);

export { connected as Search };
