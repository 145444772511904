const AuctionIcon = () => {
  return (
    <svg fill="none" height="44" viewBox="0 0 44 44" width="44" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4285 38.1865V39.0865H23.3285C24.2069 39.0865 24.9187 39.7983 24.9187 40.6768V42.0049C24.9187 42.1955 24.7643 42.35 24.5736 42.35H1.99543C1.80507 42.35 1.65031 42.1952 1.65031 42.0049V40.6768C1.65031 39.7983 2.36213 39.0865 3.24055 39.0865H4.14055V38.1865V36.6979C4.14055 35.2238 5.34134 34.023 6.81541 34.023H19.7536C21.2277 34.023 22.4285 35.2238 22.4285 36.6979V38.1865Z"
        stroke="#B8B8B8"
        strokeWidth="1.8"
      />
      <path
        d="M37.1793 14.4542C36.4778 14.9828 35.4806 14.8427 34.9519 14.1413L27.4589 4.19764C26.9302 3.49607 27.0704 2.49903 27.7718 1.97034C28.473 1.4418 29.4701 1.58195 29.9985 2.2834L37.492 12.2273C38.0207 12.9289 37.8804 13.9258 37.1793 14.4542Z"
        stroke="#B8B8B8"
        strokeWidth="1.8"
      />
      <path
        d="M19.0408 27.8041C18.3393 28.3327 17.3421 28.1926 16.8134 27.4912L9.3204 17.5476C8.7917 16.846 8.9319 15.849 9.6333 15.3203C10.3345 14.7917 11.3316 14.9319 11.86 15.6333L19.3535 25.5772C19.8822 26.2788 19.7419 27.2757 19.0408 27.8041Z"
        stroke="#B8B8B8"
        strokeWidth="1.8"
      />
      <path
        d="M27.9042 21.4602C26.3133 22.659 23.8523 23.8591 22.0617 24.2985L14.6284 14.0777C15.5152 12.3473 16.9769 10.1215 18.4966 8.97641C19.3215 8.35479 20.6809 7.67313 22.1202 7.09839C23.2949 6.62929 24.4569 6.25746 25.3346 6.05546L32.7577 16.2623C32.3293 16.9801 31.6418 17.8555 30.8346 18.7367C29.8576 19.8031 28.7709 20.8071 27.9042 21.4602Z"
        stroke="#B8B8B8"
        strokeWidth="1.8"
      />
      <path
        d="M30.8114 24.6249C31.2424 24.3 31.6467 23.9546 32.0298 23.5991L32.4969 24.219L29.957 26.1328L29.4899 25.513C29.9371 25.2426 30.3803 24.9495 30.8114 24.6249Z"
        fill="#F0AF0D"
        stroke="#B8B8B8"
        strokeWidth="1.8"
      />
      <path
        d="M39.4894 38.7838L32.5376 29.5585L35.0776 27.6446L42.0293 36.8695M39.4894 38.7838C40.018 39.4853 41.015 39.6253 41.7163 39.0969C42.4177 38.5683 42.5578 37.5712 42.0293 36.8695M39.4894 38.7838L38.7706 39.3254M42.0293 36.8695C42.0294 36.8696 42.0293 36.8694 42.0293 36.8695Z"
        stroke="#B8B8B8"
        strokeWidth="1.8"
      />
    </svg>
  );
};

export { AuctionIcon };
