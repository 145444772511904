import Select, { SelectOption } from '@components/select';
import Skeleton from '@components/skeleton';
import pluralize from 'ati-utils/pluralize';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { CompactViewButton } from 'components/CompactViewButton';
import { Field } from 'components/Field';
import { SearchResultsPagination } from 'components/SearchResultsPagination';
import { itemsPerPageCookieName } from 'constants/ui';
import { useStore } from 'hooks/useStore';
import { PrettyLoad } from 'layout/Search/PrettyLoad';
import { PopupIds } from 'store/UIStore/Popups';
import { camelToSnakeObj, cleanEmpty } from 'utils/objects';
import { tweakDescriptorForTendersCard } from 'utils/prettyLoad';
import { cookie } from 'utils/storage';
import { interpolate } from 'utils/string/format';

import { Sorting } from '../Filters/Sorting';
import { EmptyResults } from './EmptyResults';
import { SubscribeButton } from './SubscribeButton';

import css from './SearchResults.module.css';

const SearchResults = () => {
  const {
    app: {
      onLoadsHide,
      selectedLoads: { toggleSelection },
      loads,
      searchResult,
      getRequestData,
      searchLoads,
      loading,
      i18n,
      hiddenLoadsCount,
      showHiddenLoads,
      hasLoads,
      updateLoad,
    },
    ui,
    view,
    profile,
    followedLoads: {
      favorites: { updateLoads: updateFavoritesLoads, handleFavoritesFAQPopoverClose },
      loadsWithOffers: { updateLoads: updateLoadsWithOffers },
      loadsWithAuctions: { updateLoads: updateLoadsWithAuctions },
    },
  } = useStore();

  const callbacks = useMemo(
    () => ({
      hideLoads: onLoadsHide,
      selectLoads: toggleSelection,
      onRemoveLoadFromFavorites: updateFavoritesLoads,
      onAddLoadToFavorites: updateFavoritesLoads,
      onOfferChange: updateLoadsWithOffers,
      onAuctionChange: updateLoadsWithAuctions,
      onFavoritesFAQPopoverClose: handleFavoritesFAQPopoverClose,
      onFilterTabOpen: ui.handleTabChange,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const descriptor = useMemo(
    () => tweakDescriptorForTendersCard(camelToSnakeObj(cleanEmpty(getRequestData(false)))),
    [loads], // eslint-disable-line react-hooks/exhaustive-deps
  );

  if (!searchResult) return null;

  const handleItemsPerPageChange = (option: SelectOption) => {
    if (!profile.data?.isUser) {
      return ui.openLoginPopup();
    }

    if (!profile.data?.isPaidUser) {
      return ui.popups.open(PopupIds.onlyPaidPopup);
    }

    ui.pagination.setCurrentPage(1);
    ui.pagination.loadsPerPage.setOption(option);
    cookie.set({ name: itemsPerPageCookieName, value: option.value, days: 365 });

    searchLoads({ isNewSearch: false });
  };

  const handleShowHiddenLoads = () => {
    if (loading.showHiddenLoads || loading.search) return;

    showHiddenLoads();
  };

  return (
    <div>
      <div className={css.searchStatus}>
        <div className={css.summary}>
          <h2 className={cn(css.totalItems, 'glz-h glz-is-size-2')}>
            {loading.search ? (
              <Skeleton height={26} width={200} />
            ) : (
              <span>
                {pluralize({ value: searchResult?.totalItems, ...i18n.app.declensions.found })}{' '}
                {view.pluralizedCount(searchResult?.totalItems, 'loads')}
              </span>
            )}
          </h2>

          {Boolean(hiddenLoadsCount) && (
            <span className={css.hiddenLoads}>
              {isFinite(hiddenLoadsCount) && (
                <span>
                  {interpolate(i18n.app.hiddenLoadsNumber, { hiddenLoadsCount: hiddenLoadsCount.toString() })}
                </span>
              )}
              {!isFinite(hiddenLoadsCount) && <span>{i18n.app.hasHiddenLoads}</span>}

              <a
                className={cn(css.showLoads, 'glz-link glz-is-medium glz-is-gray glz-is-underline')}
                onClick={handleShowHiddenLoads}
              >
                {i18n.app.showLoads}
              </a>
            </span>
          )}
        </div>

        <Sorting />

        {ui.pagination.totalSearchPages > 1 && <SearchResultsPagination className={css.pagination} />}
      </div>

      <div className={cn(css.actionsContainer, css.actionsContainer_top)}>
        <SubscribeButton />

        <CompactViewButton />

        <Field
          isRow
          containerClassName={css.sortFieldContainer}
          contentClassName={css.sortFieldContent}
          labelClassName={css.sortLabel}
          labelText={i18n.app.showLines}
        >
          <Select
            className={css.sortSelect}
            isSearchable={false}
            options={ui.pagination.loadsPerPage.options}
            value={ui.pagination.loadsPerPage.option}
            onChange={handleItemsPerPageChange}
          />
        </Field>
      </div>

      <EmptyResults isEmpty={!hasLoads} />

      <PrettyLoad
        {...searchResult}
        callbacks={callbacks}
        currentPage={ui.pagination.currentPage}
        descriptor={descriptor}
        loads={loads}
        updateLoad={updateLoad}
      />

      {ui.pagination.totalSearchPages > 1 && (
        <div className={cn(css.actionsContainer, css.actionsContainer_bottom)}>
          <SearchResultsPagination />

          <Field
            isRow
            containerClassName={css.sortFieldContainer}
            contentClassName={css.sortFieldContent}
            labelClassName={css.sortLabel}
            labelText={i18n.app.showLines}
          >
            <Select
              className={css.sortSelect}
              isSearchable={false}
              options={ui.pagination.loadsPerPage.options}
              value={ui.pagination.loadsPerPage.option}
              onChange={handleItemsPerPageChange}
            />
          </Field>
        </div>
      )}
    </div>
  );
};

const connected = observer(SearchResults);

export { connected as SearchResults };
