import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';

import { PrettyLoadPreloader } from 'components/PrettyLoadPreloader';
import { useStore } from 'hooks/useStore';
import logger from 'sentry.client.config';
import { IReactLoadsGridProps } from 'types/global';

type TPrettyLoadProps = Omit<IReactLoadsGridProps, 'siteFeatures' | 'culture'>;

const Fallback = () => <div />;
const LoadsGrid = dynamic(
  () =>
    // @ts-ignore
    import('PrettyLoad/PrettyLoad').catch(error => {
      logger.error(error);
      return Fallback;
    }),
  {
    loading: () => <PrettyLoadPreloader />,
    ssr: false,
  },
);

const PrettyLoad = (props: TPrettyLoadProps) => {
  const { callbacks, descriptor, currentPage, loads, updateLoad, ...searchResult } = props;

  const {
    app: { locale, loading },
    siteFeatures: { isSiteFeatureEnabled },
    enablerFeaturesRepo,
    ui: { isCompactPrettyLoad, isPrintMode, setPrintMode },
    dictionaries: { data },
  } = useStore();

  if (loading.search) {
    return <PrettyLoadPreloader />;
  }

  const shouldSkipRenderPrettyLoad = typeof window === 'undefined' || !loads || !loads.length;
  if (shouldSkipRenderPrettyLoad) return null;

  return (
    <LoadsGrid
      {...searchResult}
      // @ts-ignore
      callbacks={callbacks}
      culture={locale}
      currentPage={currentPage}
      descriptor={descriptor}
      dictionaries={data}
      enabler={enablerFeaturesRepo}
      isCompactAll={isCompactPrettyLoad}
      isPrintMode={isPrintMode}
      loads={loads}
      setPrintMode={setPrintMode}
      siteFeatures={isSiteFeatureEnabled}
      updateLoad={updateLoad}
      userId={window.atiUser.profile?.ati_code}
    />
  );
};

const connected = observer(PrettyLoad);

export { connected as PrettyLoad };
